body {
    text-align: center;
    font-size: 14px;
}

.header {
    height: auto;
    background: #f5f5f5;
}

.footer {
    color: white;
    background: #656665;
}

.a-footer {
    color: white;
    text-decoration: none;
}

.box {
    padding-top: 30px;
    padding-bottom: 50px;
}

@media screen and (max-width: 575px)  {
    .box {
        max-width: 85%;
    }
}

.box-container {
    border: 2px white;
    border-radius: 20px;
    padding: 1rem;
    align-content: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.31);
}

.box-text {
    /*display: inline-flex;*/
    font-size: 20px;
    font-weight: 300;
    /*text-align: center;*/
    /*overflow-wrap: break-word; */
    margin: 5px auto; /* Noch ändern */
}

.banner {
    background: #f4f4f4;
    position: relative;
    padding: 40px 30px;
}

.banner-inline {
    padding: 50px 50px;
}

h1, h2, h3, h4, h5 {
    font-weight: normal;
}

h4 {
    font-size: medium;
    margin: 4px;
}

p {
    font-size: medium;
    color: rgb(102, 102, 102);
    margin: 4px;
}

.h2-top {
    font-size: 30px;
    line-height: 4rem;
}

.link {
    text-decoration: none;
}

.text-box {
    padding-top: 27px;
    padding-bottom: 27px;
    margin: 0 20px;
}

.text-inner {
    line-height: 24px;
}

.text-line {
    /* max-width: 50%; */
    display: inline-flex;
    text-align: center;
    line-height: 2;
    overflow-wrap: break-word;
    font-size: 22px;
    font-weight: 500;
}

.text-col {
    font-size: 18px;
    overflow-wrap: break-word;
    line-height: 1.5rem;
    margin: 24px 0;
}

.text-col-title {
    font-size: 100%;
    overflow-wrap: break-word;
    line-height: 1em;
}

.text-icon {
    font-size: 48px;
    line-height: 0;
    padding: 30px 25px;
}

.skill-box {
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out;
}

.large-button {
    font-size: 1.8rem;
    line-height: 0;
    border-width: 1.8rem;
}

.extra-large-button {
    font-size: 35px;
    line-height: 0;
    border-width: 25px;
}

.extra-large-button-multi {
    font-size: 35px;
    line-height: 1.5rem;
    border-width: 25px;
}

@media screen and (max-width: 700px) {
    .large-button {
        font-size: 1.2rem;
        line-height: 0;
        border-width: 1.2rem;
    }

    .extra-large-button {
        font-size: 25px;
        border-width: 25px;
    }
}

@media screen and (max-width: 400px) {
    .extra-large-button {
        font-size: 20px;
        border-width: 20px;
    }
}

.submit-button {
    font-size: 22px;
    line-height: 0;
    border-width: 20px;
    margin-top: 80px;
    margin-bottom: 40px;
}

@media screen and (max-width: 680px) {
    .submit-button {
        font-size: 18px;
        margin-top: 40px;
        margin-bottom: 20px;
    }
}

.responsive-image {
    max-width: 500px;
    width: 100%;
    border-radius: 16px 16px 16px 16px;
}
